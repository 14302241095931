import React, { useEffect } from "react"

// Import typefaces
import "typeface-merriweather"
import "typeface-montserrat"

import profilePic from "../assets/avatar.jpg"
import { rhythm } from "../utils/typography"
import SocialLinks from "./SocialLinks"
import { addCssCursor, Typewriter } from "./Typelighter"

const social = [
    {
        url: `https://twitter.com/ulysoh`,
    },
    {
        url: `https://github.com/ulyso`,
    },
    {
        url: `https://instagram.com/ulysoh`,
    },
    // {
    //   url: `https://www.linkedin.com/company/narative/`,
    // },
    {
        url: `https://dribbble.com/ulyso`,
    },
    // {
    //   url: `https://youtube.com`,
    // },
]

function Bio() {
    useEffect(() => {
        const elements = document.getElementsByClassName("typeWriter")
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < elements.length; i++) {
            const userText = elements[i].getAttribute("data-text")
            if (userText) {
                // Creating an object and parsing the string into an array
                // tslint:disable-next-line: no-unused-expression
                new Typewriter(elements[i], JSON.parse(userText))
            }
        }
        addCssCursor()
    }, [])

    return (
        <div
            style={{
                display: "flex",
                marginBottom: rhythm(2.5),
            }}
        >
            <img
                src={profilePic}
                alt={`Ulyso`}
                style={{
                    marginRight: rhythm(1 / 2),
                    marginBottom: 0,
                    width: rhythm(2),
                    height: rhythm(2),
                }}
            />
            <p>
                Written by{" "}
                <strong>
                    <span
                        className="typeWriter"
                        data-max="1.5"
                        data-text='["Ulyso"]'
                    />
                </strong>{" "}
                .{"   "}
                <SocialLinks links={social} />
            </p>
        </div>
    )
}

export default Bio
