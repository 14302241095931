// Social
import Dribbble from "./social/Dribbble.Icon"
import Facebook from "./social/Facebook.Icon"
import Github from "./social/Github.Icon"
import Instagram from "./social/Instagram.Icon"
import LinkedIn from "./social/LinkedIn.Icon"
import Medium from "./social/Medium.Icon"
import Patreon from "./social/Patreon.Icon"
import Paypal from "./social/Paypal.Icon"
import Twitter from "./social/Twitter.Icon"
import Unsplash from "./social/Unsplash.Icon"
import YouTube from "./social/YouTube.Icon"

// UI
import ChevronLeft from "./ui/ChevronLeft.Icon"
import Copied from "./ui/Copied.Icon"
import Copy from "./ui/Copy.Icon"
import Ex from "./ui/Ex.Icon"
import Link from "./ui/Link.Icon"
import Rows from "./ui/Rows.Icon"
import Tiles from "./ui/Tiles.Icon"
import ToggleClose from "./ui/ToggleClose.Icon"
import ToggleOpen from "./ui/ToggleOpen.Icon"

export default {
    Dribbble,
    Facebook,
    Instagram,
    Twitter,
    LinkedIn,
    Medium,
    Github,
    Unsplash,
    YouTube,
    Patreon,
    Paypal,

    ChevronLeft,
    Copied,
    Copy,
    Ex,
    Link,
    ToggleClose,
    ToggleOpen,
    Rows,
    Tiles,
}
