/**
 * Fork of https://github.com/EdernClemente/TypeLighterJS
 */

// INJECTING CSS, you can also paste it in your CSS file.
export function addCssCursor() {
    "use strict"
    const css = document.createElement("style")
    css.type = "text/css"
    css.innerHTML =
        ".cursor {color:inherit;position:relative;font:inherit;color:inherit;line-height:inherit;animation: Cursor 1s infinite;}@keyframes Cursor{0%{opacity: 1;}50%{opacity: 0;}100%{opacity: 1;}};"
    document.body.appendChild(css)
}

export class Typewriter {
    public userText: any
    public speed: number
    public startDelay: number
    public endDelay: number
    public isRandomlyTimed: boolean
    public loopThreshold: number | boolean
    public deleteSpeed: boolean
    public el: any
    public isRunning: boolean
    public loopNumber: number
    public text: string
    public isDeleting: boolean
    public handler: () => void
    constructor(el: any, userText: any) {
        this.userText = userText
        this.speed = parseInt(el.getAttribute("data-speed"), 10) || 1
        this.startDelay = parseInt(el.getAttribute("data-start"), 10) || 500
        this.endDelay = parseInt(el.getAttribute("data-end"), 10) || 2000
        this.isRandomlyTimed = el.getAttribute("data-random") !== "false"
        this.loopThreshold = parseFloat(el.getAttribute("data-max")) || false
        this.deleteSpeed = el.getAttribute("data-dltSpeed") !== "false"
        this.el = el
        this.isRunning = false
        this.loopNumber = 0
        this.text = ""
        this.isDeleting = false
        // tslint:disable-next-line:no-empty
        this.handler = () => {}

        const cursor = document.createElement("span")
        cursor.className = "cursor"
        cursor.innerHTML = "|"
        this.el.parentNode.appendChild(cursor)

        if (el.getAttribute("data-checkVisible") === "true") {
            this.checkVisible()
        } else {
            this.loop()
        }
    }

    public loop() {
        if (this.loopThreshold && this.loopNumber > this.loopThreshold) {
            return
        }

        let delta

        // The first execution is delayed
        if (this.loopNumber !== 0) {
            // Grabbing the appropriate string
            const fullText = this.userText[
                Math.floor(this.loopNumber - 0.5) % this.userText.length
            ]

            this.text = fullText.substring(
                0,
                this.text.length + (this.isDeleting ? -1 : 1),
            )

            this.el.innerHTML = '<span class="wrap">' + this.text + "</span>"

            if (this.text === "" && this.isDeleting) {
                this.isDeleting = false
                this.loopNumber += 0.5
                delta = this.startDelay
            } else if (this.text === fullText && !this.isDeleting) {
                delta = this.endDelay
                this.isDeleting = true
                this.loopNumber += 0.5
            } else {
                if (this.isDeleting && this.deleteSpeed) {
                    delta = 50 / this.speed
                } else {
                    delta = this.isRandomlyTimed
                        ? (200 - Math.random() * 10) / this.speed
                        : 100 / this.speed
                }
            }
        } else {
            delta = this.startDelay
            this.loopNumber += 0.5
        }

        setTimeout(() => this.loop(), delta)
    }

    // Check the visibility of an element when "checkVisible" is enabled
    public checkVisible() {
        const that = this

        this.handler = onVisibilityChange(this.el, () => {
            if (window.removeEventListener) {
                removeEventListener("DOMContentLoaded", that.handler, false)
                removeEventListener("load", that.handler, false)
                removeEventListener("scroll", that.handler, false)
                removeEventListener("resize", that.handler, false)
            } else if (window.detachEvent) {
                window.detachEvent("onDOMContentLoaded", that.handler) // IE9+ :(
                window.detachEvent("onload", that.handler)
                window.detachEvent("onscroll", that.handler)
                window.detachEvent("onresize", that.handler)
            }

            that.loop()
        })

        if (window.addEventListener) {
            addEventListener("DOMContentLoaded", this.handler, false)
            addEventListener("load", this.handler, false)
            addEventListener("scroll", this.handler, false)
            addEventListener("resize", this.handler, false)
        } else if (window.attachEvent) {
            window.attachEvent("onDOMContentLoaded", this.handler) // IE9+ :(
            window.attachEvent("onload", this.handler)
            window.attachEvent("onscroll", this.handler)
            window.attachEvent("onresize", this.handler)
        }
        this.handler()
    }
}

function isElementInViewport(el: any) {
    const rect = el.getBoundingClientRect()

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    )
}

function onVisibilityChange(el: any, callback: any) {
    let oldVisible = false
    return () => {
        const visible = isElementInViewport(el)
        if (visible !== oldVisible) {
            oldVisible = visible
            if (typeof callback === "function") {
                callback()
            }
        }
    }
}
