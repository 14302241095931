import { graphql, Link } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import Bio from "../components/Bio"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import "../styles/global.scss"
import { rhythm } from "../utils/typography"

interface IProp {
    data?: any
    pageContext?: any
    location?: any
}

class IndexPage extends React.Component<IProp> {
    public render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const siteDescription = data.site.siteMetadata.description
        const posts = data.allMdx.edges
        const { currentPage, numPages } = this.props.pageContext
        const isFirst = currentPage === 1
        const isLast = currentPage === numPages
        const prevPage =
            currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
        const nextPage = (currentPage + 1).toString()

        return (
            <div
                style={{
                    backgroundColor: "var(--bg)",
                }}
            >
                <Layout location={this.props.location} title={siteTitle}>
                    <Helmet
                        htmlAttributes={{ lang: "en" }}
                        meta={[
                            { name: "description", content: siteDescription },
                            {
                                name: "viewport",
                                content:
                                    "width=device-width, initial-scale=1, shrink-to-fit=no",
                            },
                        ]}
                        title={siteTitle}
                    />
                    <SEO title={siteTitle} />
                    <Bio />
                    {posts.map(({ node }: any) => {
                        const title = node.frontmatter.title || node.fields.slug
                        return (
                            <div key={node.fields.slug}>
                                <h3
                                    style={{
                                        marginBottom: rhythm(1 / 4),
                                    }}
                                >
                                    <Link
                                        style={{ boxShadow: "none" }}
                                        to={node.fields.slug}
                                    >
                                        {title}
                                    </Link>
                                    <Link
                                        style={{
                                            float: "right",
                                            color: "#000000",
                                            padding: "0 10px",
                                            borderRadius: 2,
                                            height: 40,
                                            fontSize: 12,
                                            backgroundColor: "#ffde7d",
                                            textAlign: "center",
                                            lineHeight: "42px",
                                            boxShadow: "none",
                                        }}
                                        to={
                                            "/categories/" +
                                            node.frontmatter.categories
                                        }
                                    >
                                        {node.frontmatter.categories}
                                    </Link>
                                </h3>
                                <small>创建时间：{node.frontmatter.date}</small>{" "}
                                <small>({node.parent.modifiedTime}修改)</small>{" "}
                                <small>阅读时间 {node.timeToRead} 分钟</small>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: node.excerpt,
                                    }}
                                />
                            </div>
                        )
                    })}
                    <ul
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            alignItems: "center",
                            listStyle: "none",
                            padding: 0,
                        }}
                    >
                        <li>
                            {isFirst ? null : (
                                <Link
                                    to={
                                        prevPage !== "/"
                                            ? `/${prevPage}`
                                            : `${prevPage}`
                                    }
                                    rel="prev"
                                >
                                    ← Previous Page
                                </Link>
                            )}
                        </li>
                        {Array.from({ length: numPages }, (_, i) => (
                            <li
                                key={`pagination-number${i + 1}`}
                                style={{
                                    margin: 0,
                                }}
                            >
                                <Link
                                    to={`/${i === 0 ? "" : i + 1}`}
                                    style={{
                                        padding: rhythm(1 / 4),
                                        textDecoration: "none",
                                        color:
                                            i + 1 === currentPage
                                                ? "#ffffff"
                                                : "",
                                        background:
                                            i + 1 === currentPage
                                                ? "#007acc"
                                                : "",
                                    }}
                                >
                                    {i + 1}
                                </Link>
                            </li>
                        ))}
                        <li>
                            {isLast ? null : (
                                <Link
                                    to={
                                        nextPage !== 0
                                            ? `/${nextPage}`
                                            : `${nextPage}`
                                    }
                                    rel="next"
                                >
                                    Next Page →
                                </Link>
                            )}
                        </li>
                    </ul>
                </Layout>
            </div>
        )
    }
}

export default IndexPage

export const pageQuery = graphql`
    query blogPageQuery($skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                title
                description
            }
        }
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { published: { eq: true } } }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    excerpt
                    timeToRead
                    frontmatter {
                        date(fromNow: true, locale: "zh-CN")
                        title
                        categories
                    }
                    parent {
                        ... on File {
                            modifiedTime(fromNow: true, locale: "zh-CN")
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
