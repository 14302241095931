import styled from "@emotion/styled"
import React from "react"
import Icons from "../icons"
// tslint:disable-next-line: no-var-requires
const urls = require("url")

const icons: any = {
    dribbble: Icons.Dribbble,
    linkedin: Icons.LinkedIn,
    twitter: Icons.Twitter,
    facebook: Icons.Facebook,
    instagram: Icons.Instagram,
    github: Icons.Github,
    youtube: Icons.YouTube,
    medium: Icons.Medium,
    unsplash: Icons.Unsplash,
    patreon: Icons.Patreon,
    paypal: Icons.Paypal,
}

const getHostname = (url: any) => {
    return urls
        .parse(url.toLowerCase())
        .hostname.replace("www.", "")
        .split(".")[0]
}

function SocialLinks({ links, fill = "#73737D" }: any) {
    if (!links) { return null }
    //   console.log(links)

    return (
        <>
            {links.map((option: any) => {
                const name = getHostname(option.url)
                const Icon = icons[name]
                if (!Icon) {
                    throw new Error(
                        `unsupported social link name=${name} / url=${option.url}`,
                    )
                }
                return (
                    <SocialIconContainer
                        key={option.url}
                        target="_blank"
                        rel="noopener"
                        data-a11y="false"
                        aria-label={`Link to ${option.url}`}
                        href={option.url}
                    >
                        <Icon fill={fill} />
                        <Hidden>Link to ${option.url}</Hidden>
                    </SocialIconContainer>
                )
            })}
        </>
    )
}

export default SocialLinks

const SocialIconContainer = styled.a`
    position: relative;
    margin-left: 3.2rem;
    text-decoration: none;
    max-width: 16px;
    box-shadow: none;

    &:hover {
        svg {
            &:hover * {
                fill: '#000000';
            }
            * {
                transition: fill 0.25s var(--ease-in-out-quad);
            }
        }
    }

    &:first-of-type {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &[data-a11y='true']:focus::after {
        content: '';
        position: absolute;
        left: -50%;
        top: -20%;
        width: 200%;
        height: 160%;
        background: rgba(255, 255, 255, 0.01);
        border-radius: 5px;
    }
`

const Hidden = styled.span`
    width: 0px;
    height: 0px;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    display: inline-block;
`
